import { useCallback } from 'react'
import { useSnackbar } from 'notistack'

const useMutationSnackbarMessages = ({
  dataAttribute,
  customErrorMessage,
  customSuccessMessage = 'Operation completed successfully.',
  afterCompletedFn,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const onError = useCallback(
    error => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 3000,
      })
    },
    [enqueueSnackbar]
  )

  const onCompleted = useCallback(
    data => {
      const result = data[dataAttribute]
      if (result?.errors?.length) {
        const errorMessages = result.errors.map(e => `${e.key} ${e.message}`).join(', ')
        enqueueSnackbar(customErrorMessage || `Errors: ${errorMessages}`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      } else {
        enqueueSnackbar(customSuccessMessage, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (afterCompletedFn) {
          afterCompletedFn(result)
        }
      }
    },
    [enqueueSnackbar, dataAttribute, customErrorMessage, customSuccessMessage, afterCompletedFn]
  )

  return { onError, onCompleted }
}

export default useMutationSnackbarMessages
